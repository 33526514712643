<template>
    <default-template>
        <v-layout justify-center align-start>
            <v-responsive width="100%" max-width="1200" class="py-8" :class="{ 'px-4': $vuetify.breakpoint.mobile }" >
                <slot />
            </v-responsive>
        </v-layout>

        <template slot="fab" slot-scope="{showScroll, scrollTo}">
            <slot name="fab" v-bind:showScroll="showScroll" v-bind:scrollTo="scrollTo">
            </slot>
        </template>
    </default-template>
</template>
<script>
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
    components: {
        DefaultTemplate
    }
}
</script>